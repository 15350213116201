.alarm-container {

  .alarm-link {
    opacity: 0.7;
    color: white;
    text-decoration: none;
    background-color: #0d8ddc;
    padding: 15px 10px;
    border-radius: 2px;
    transition: opacity 0.2s ease-in-out;
    display: inline-block;
    min-width: 100px;
    text-align: center;

    &.active {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }

  .nav-item:hover {
    a {
      color: white;
    }
  }
}